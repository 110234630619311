import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import "../shared.scss";

const VivToast = forwardRef((props, ref) => {
  const toastTopCenter = useRef(null);
  
  useImperativeHandle(ref, () => ({
    showMessage(severity = "error", summary = "", detail = "", icon = "i-notify") 
    {
      toastTopCenter.current.show({severity: severity,summary: summary,detail: detail,icon: icon,life: 3000});
    },
    showTemplateMessage(content){
      toastTopCenter.current.show(content);
    }
  }));

  const onHide = () => {
    if(props?.onHide){
      props?.onHide();
    }
  }

  return (
    <div className="card flex justify-content-center">
      <Toast icon={props.icon} ref={toastTopCenter} position="top-center" onHide={onHide} />
    </div>
  );
});

export default VivToast;
