import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuLink = (props) => {
    const location = useLocation();
    //const isActive = location.pathname == props.to;
    const isActive = location.pathname.includes(props.to);
    return(
        <Link to={props.to} className={`${props.classes} ${isActive ? 'active' : 'notactive'}`}>
            <span className={`p-menuitem-icon ${props.icon}`} data-pc-section="headericon"></span>
            <span className="p-menuitem-text" data-pc-section="headerlabel">{props.text}</span>
        </Link>
    )
}

export default MenuLink;