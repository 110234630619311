import React, { useRef, useState, useEffect } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "../assets/layout/_notification.scss";
import { hideLoader,setActiveGridTabInd,setBadgeValue,showLoader } from "../redux/mainSlice";
import { Badge } from "primereact/badge";
import GridTabs from "../shared/GridTabs";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import httpClient from "../_util/api";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const parentOp = useRef(null);
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const navigate=useNavigate();
  const badgeValue = useSelector((state) => state.mainSlice.badgeValue);
  const confirm1 = (data) => {   
    if (data && data.id) {
      getNotificationById(data.id);
    } else {
      console.error("Invalid data format - missing 'id' property:", data);
    }
  };
  
  const [displayDialog, setDisplayDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const gridTabs = [{ label: "All" }, { label: "Unread" }];
  const [apidata, setapidata] = useState([]);
  const [earlydata, setearlydata] = useState([]);

  const detailview = () => {
    navigate("/order/sale-request/all-notification")
    parentOp.current.hide();
   
  };
  

  const handleMarkRead = async () => {  
   console.log('for marking read notification');
   
  };

  const showOverlay = (e) => {
    parentOp.current.show(e);
    /* getallnotificationapi();
    viewallnotificationapi(); */
  };
  const onHideDialog = () => {
    setDisplayDialog(false);
    /* getallnotificationapi();  */
  };
  const handleTabChange = (data) => {
    setActiveTabIndex(data);
    dispatch(setActiveGridTabInd(data));
  };

  /* const getNotificationById = async (notificationId) => {
    dispatch(showLoader());
    
      const resp = await httpClient.get(`Notification/GetNotificationById/${notificationId}`).catch((error) => { dispatch(hideLoader()); });
      if (resp?.status === 200) {
        setDialogData(resp?.data.data || {});
        setDisplayDialog(true);

        dispatch(hideLoader());
      }
 
  }; */

  /* const getallnotificationapi = async () => {

    let resp = await httpClient.get(`Notification/GetAllNotification`).catch((error) => { dispatch(hideLoader()); });
     if (resp?.status === 200) {
      const dataLength = resp?.data?.data?.length || 0;
      
      setapidata(resp?.data?.data || []);


      
      dispatch(setBadgeValue(dataLength));
    }
  }
  const viewallnotificationapi = async () => {

    let resp = await httpClient.get(`Notification/ViewAllNotification`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.status == 200) {
      setearlydata(resp?.data?.data || []);

    }
  }
  
useEffect(() => {
  getallnotificationapi();
  viewallnotificationapi();

}, []); */


  return (
    <>
    </>
  );
};

export default Notification;
