import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import httpClient from "./api";
import { useDispatch } from "react-redux";
import { setUserDetail, setUserMenus, setUserRole } from "../redux/mainSlice";

const ProtectedLogin = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkUserToken = async () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        } else{
            setIsLoggedIn(true);
            return navigate('/profile');
        }
        // let apiResp = await httpClient.get("whomi").catch((error) => { localStorage.removeItem("user-token"); });

        // if (apiResp?.status == 200) {
        //   dispatch(setUserMenus(apiResp.data.data.menus));
        //   dispatch(setUserRole(apiResp.data.data.role));
        //   dispatch(setUserDetail(apiResp.data.data.user));
        // }
        
    }

    useEffect(() => {
        checkUserToken();
    }, []);


    return (
        <React.Fragment>
            {
                !isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedLogin;